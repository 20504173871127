.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: rgb(239, 126, 0);
  width: 100%;
  height: 100%;
}

#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.dark {
  background-color: rgb(38, 38, 38);
  color: rgb(200, 200, 200);
  border: 0;
}

.page-link {
    color: rgb(248, 178, 27); 
  }

.dark-orange{
  color : black;
  background-color: rgb(248, 178, 27);
}

.margin-6{
  margin-top: 7em;
}

.margin-bottom{
  margin-bottom: 4em;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  /* Sayfa arka planını dondurmak için bir arka plan ekliyoruz */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Diğer elementlerin üstünde göstermek için yüksek bir z-indeks kullanıyoruz */
}

.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.favorite-departments {
  bottom: 0;
  left: 25%;
  z-index: 9999;
  width: 50%;
  border-top: 3px rgb(239, 126, 0) solid;
}

.accordion-item {
  max-height: 500px;
  overflow-y: auto;
}

.accordion-body {
  max-height: 80%;
}



.Toastify__toast{
  background-color: rgb(44,48,52) !important;
  border: 1px solid rgb(25,135,84);
}


@media (max-width: 1200px) {
  .list-group-item {
    display: block;
  }
}

@media (max-width: 768px) {
  .favorite-departments {
    width: 100%;
    left: 0;
  }

  .table {
    width: 100%;
    font-size: 7px;
  }
  .accordion-button {
    align-items: center;
    display: inline-block;
  }
}

/* Bu kısım artık kullanımıyor ama ne olur ne olmaz diye dursun */
@media (max-width: 99) {

  .main-table-container {
    overflow-x: auto; /* Yatay kaydırma çubuğunu etkinleştir */
    max-width: 100%; /* Tablo genişliğini maksimum %100 olarak ayarla */
  }
  
  .table {
    table-layout: fixed; /* Tablo boyutlarını sabit tut */
    width: auto; /* Tablo genişliğini otomatik olarak ayarla */
    min-width: 600px; /* Tablo en az bu kadar geniş olsun (istediğiniz genişliği ayarlayabilirsiniz) */
  }

  .favorite-departments {
    width: 100%;
    left: 0;
  }

  .table {
    width: 100%;
    table-layout: fixed; /* Tablonun boyutunun sabit kalması için table-layout özelliğini kullanıyoruz */
    font-size: 9px;
  }
}

